<template>
    <div class="w-full p-5 text-base-content-300">
        <div class="flex flex-col gap-2">
            <div v-if="isLoading" class="flex min-h-full min-w-full justify-center items-center rounded-md">
                <loader class="my-5" height="32px" width="32px" :loading="isLoading" />
                Loading
            </div>
            <div v-if="packageData && !isLoading" class="flex flex-col gap-2">
                <span class="text-base-content-300 text-md font-medium"> SLA (in Days)</span>
                <FormulateForm  class="min-h-full flex-1">
                    <div class="flex flex-col justify-between min-h-full">
                        <div class="form flex w-full flex-col flex-1">
                            <FormulateInput :disabled="!checkPermission('package.edit') || this.$route.params.action == 'view'" class="w-full" placeholder="SLA in Days" type="number" validation="required|number|min:0,number" v-model="packageData.turn_around_time" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                        <div class="card-actions justify-end" v-allow="'package.edit'" v-if="this.$route.params.action !== 'view'">
                            <Button text="Update" type="primary" :disabled="this.$route.params.action == 'view'" @click="updateTat()"/>
                        </div>
                    </div>
                </FormulateForm>
            </div>
        </div>
    </div>
</template>

<script>
const Button = () => import("@/components/button");
// import axios from "@/axios";
// import loader from "@/components/loader";
import axios from "@/axios";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { checkPermission } from "@shared/utils/functions";

export default {
    name: "tat",
    components: {
        // loader,
        Button,
    },
    data: () => ({
        packageData: null,
        isLoading: false,
    }),
    async mounted() {
        await this.fetchScreeningData();
    },
    computed: {
        ...mapGetters(["getPackage"]),
    },

    methods: {
        checkPermission,
        ...mapActions(["fetchPackageData"]),
        ...mapMutations(["SET_PACKAGE"]),
        async fetchScreeningData() {
            this.isLoading = true;
            if (!this.getPackage) {
                await this.fetchPackageData(this.$route.params.packageName);
                this.packageData = this.getPackage;
            }
            this.packageData = this.getPackage;
            this.isLoading = false;
        },
        async updateTat() {
            if(this.$route.params.action == 'view'){
                return
            }
            const url = `/screening-package/${this.packageData.id}`;
            const payload = {
                turn_around_time: this.packageData.turn_around_time,
            };
            if(this.packageData.turn_around_time < 0){
                this.$toast.error("Package turn around time can't be less than 0");
                return;
            }
            try {
                await axios.put(url, payload);
                this.SET_PACKAGE = { ...this.getPackage, turn_around_time: this.packageData.turn_around_time };
                this.$toast.success("Package turn around time updated.");
            } catch (error) {
                this.$toast.error(error.response.data.detail[0]?.msg || "Could not update package turn around time.");
            }
        },
    },
};
</script>

<style scoped></style>
